import differenceInSeconds from 'date-fns/differenceInSeconds'
import addHours from 'date-fns/addHours'

import { Container } from 'reactstrap'


const Timer = (props) => {
    const { contractions } = props

    const averageLengthBetweenReducer = (accumulator, value, index, array) => {
        if (index + 1 < array.length) {
            return accumulator + differenceInSeconds(array[index + 1].start, value.start)
        }
        return accumulator
    }

    const averageLengthReducer = (accumulator, value) => {
        return accumulator + differenceInSeconds(value.end, value.start)
    }
    
    const getAverageLengthBetween = (contractions) => {
        return contractions.length > 1 ? (contractions.reduce(averageLengthBetweenReducer , 0) / (contractions.length - 1) ) : 0
    }

    const getAverageLength = (contractions) => {
        return contractions.length > 0 ? (contractions.reduce(averageLengthReducer, 0) / contractions.length) : 0
    }

    const formatDuration = ( inSeconds ) => {
        let roundedInSeconds = Math.round(inSeconds)
        let minutes = Math.floor(roundedInSeconds / 60)
        let seconds = roundedInSeconds % 60

        let formattedMinutes = minutes === 1 ? minutes + " minute " :  minutes + " minutes "
        let formattedSeconds = seconds === 1 ? seconds + " second" :  seconds + " seconds"

        let formatted = minutes === 0 ? formattedSeconds : formattedMinutes + formattedSeconds
        return (
            <p className="font-weight-bold"> {formatted} </p>
        )
    }

    let filteredContractions = contractions.filter((value) => value.start > addHours(Date.now(), -1))
    let lengthBetween = getAverageLengthBetween(filteredContractions)
    let length = getAverageLength(filteredContractions)

    return (
        <Container>
            <h5 className="font-weight-bold mb-0">In the last hour</h5>
            <p className="mb-0">Average time between contractions:</p>
            {formatDuration(lengthBetween)}
            <p className="mb-0">Average contraction length:</p>
            {formatDuration(length)}
        </Container>   
    )
}

export default Timer
