import React, { useEffect, useState } from 'react'

function Toggle() {
    const DARK = 'dark'
    const LIGHT = 'light'
    const [theme, setTheme] = useState(LIGHT)

    const handleOnClick = () => {
        let newTheme = (theme === LIGHT) ? DARK : LIGHT
        setTheme(newTheme)
        switchCssTheme()
        localStorage.setItem('theme', newTheme)
    }

    const switchCssTheme = () => {
        document.body.classList.toggle('dark-theme')
    }

    useEffect(() => {
        let storedTheme = localStorage.getItem('theme')
        if (storedTheme === DARK) {
            setTheme(DARK)
            switchCssTheme()
        }
    }, [])

    return (
        <div class="dark-mode-switch" onClick={handleOnClick}/>
    )
}

export default Toggle