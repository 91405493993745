
import Timer from './components/Timer'
import LineChart from './components/Graph'
import Toggle from './components/Toggle'

import { useState, useEffect } from 'react'

import { Button, UncontrolledTooltip, Col, Container } from "reactstrap"


const App = () => {
  const [contractions, setContractions] = useState([])
  const [contractionStart, setContractionStart] = useState(null)

  const saveToLocalStorage = ( value ) => {
    localStorage.setItem("contractions", JSON.stringify(value))
  }

  const updateContractions = (values) => {
    setContractions(values)
    saveToLocalStorage(values)
  }

  const startStopHandler = () => {
    if (!contractionStart) {
      setContractionStart(Date.now())
    } else {
      updateContractions([...contractions, {start: contractionStart, end: Date.now()}])
      setContractionStart(null)
    }
  }

  const undoHandler = () => {
    if (contractionStart) {
      // if a contraction is ongoing, reset it
      setContractionStart(null)
    } else {
      // otherwise, delete the last contraction and set its start prop as contractionStart
      let updatedContractions = [...contractions]
      let lastContraction = updatedContractions.pop()
      setContractionStart(lastContraction.start)
      updateContractions(updatedContractions)
    }
  }

  useEffect(() => {
    let fromLocalStorage = JSON.parse(localStorage.getItem("contractions"))

    if (fromLocalStorage) {
      setContractions(fromLocalStorage)
    }
  }, [])

  return (
    <Container className="text-center flex-direction-column">
      <Toggle/>
      <Col>
        <h1 className='mt-4'>
          Countraction
        </h1>
        <h5>
          A contraction timer.
        </h5>
        <Container className="my-4" >
          <Button className="mx-1" color="primary" size="lg" onClick={startStopHandler}>
            { !contractionStart ? 'START' : 'STOP' }
          </Button>
          <Button className="mx-1" color="secondary" size="lg" id="undoButton" data-placement="top" onClick={undoHandler} disabled={contractions.length === 0 && !contractionStart}>
            UNDO
            <UncontrolledTooltip delay={0} placement="top" target="undoButton">
              Undo the last action (contraction Start or Stop).
            </UncontrolledTooltip>
          </Button>
        </Container>

        <Timer contractions={contractions}/>
        <LineChart data={contractions} />
      </Col>
    </Container>
  )
}

export default App
