import { useState, useEffect } from 'react'

import {
    AnimatedLineSeries,
    Axis,
    XYChart,
  } from "@visx/xychart"

import { curveBundle } from "@visx/curve"

import addHours from 'date-fns/addHours'
import format from 'date-fns/format'
import addMilliseconds from 'date-fns/addMilliseconds'

  
const LineChart = ( props ) => {
  const [chartData, setChartData] = useState([])

  const { data } = props

  const transformData = ( originalData ) => {
    let transformedData = [
      // start of the chart is always 1 hour ago
      {event: 'helper', timestamp: addHours(Date.now(), -1)},
    ]
    for (let value of originalData) {
      transformedData.push({event: 'helper', timestamp: addMilliseconds(value.start, -500)})
      transformedData.push({event: 'start', timestamp: value.start})
      transformedData.push({event: 'end', timestamp: value.end})
      transformedData.push({event: 'helper', timestamp: addMilliseconds(value.end, 500)})
    }
    // end of the chart is always now + 1 sec to keep the line at the bottom
    transformedData.push({event: 'helper', timestamp: addMilliseconds(Date.now(), 1000)})

    return transformedData
  }

  const ACCESSORS = {
    xAccessor: (d) => new Date(d.timestamp),
    yAccessor: (d) => d.event !== 'helper' ? 1 : 0
  }

  useEffect(() => {
    const updateData = () => {
      setChartData(data.filter((value) => value.start > addHours(Date.now(), -1)))
    }
    
    // update on 'data' prop change
    updateData()

    // and update every 10 seconds
    let refreshInterval = setInterval(() => {
      updateData()
    }, 10000)
    return () => clearInterval(refreshInterval)
  }, [data])

  const tickFormat = (value) => {
    return format(value, 'HH:mm')
  }

  return (
    <XYChart
      height={300}
      xScale={{type: "time"}}
      yScale={{type: "linear"}}
      >
      <AnimatedLineSeries data={transformData(chartData)} {...ACCESSORS} curve={curveBundle}/>
      <Axis orientation="bottom" hideAxisLine={true} hideTicks={true} numTicks={3} tickFormat={tickFormat}/>
    </XYChart>
  )
}

export default LineChart